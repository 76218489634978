import React, { useEffect, useState } from "react";
import "./NewLandingPage.scss";
import { Container } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import {
  GetStarted,
  GetAllCalculatorDescription,
  GetAboutByUser,
  GetJourneyByUser,
  GetMissionVisionByUser,
  getLocalStorageItem,
} from "../../Common/Api";
import { ImageUrl } from "../../Common/Host";
import HeaderSection from "../../images/headerbanner.png";
import { Skeleton } from "antd";

const NewLandingPage = () => {
  const [getStarted, setGetStarted] = useState(null);
  const [aboutData, setAboutData] = useState(null);
  const [missionData, setMissionData] = useState(null);
  const [visionData, setVisionData] = useState(null);
  const [journeyData, setJourneyData] = useState(null); // Updated to null to handle empty state
  const [savingCalculator, setSavingCalculator] = useState(null);
  const [mortgageCalculator, setMortgageCalculator] = useState(null);
  const [budgetCalculator, setBudgetCalculator] = useState(null);
  const [debtCalculator, setDebtCalculator] = useState(null);
  const navigate = useNavigate();
  const handleRedirection = (path) => {
    navigate(`/${path}`);
  };

  const token = getLocalStorageItem("Userlogintoken");

  const faq = [
    {
      question: "What is the duration of the finance course?",
      answer:
        "The course is self-paced, typically consisting of multiple modules.",
    },
    {
      question: "Is this course suitable for beginners?",
      answer:
        "Yes, this course is designed for learners of all levels, including beginners with no prior finance knowledge.",
    },
    {
      question: "Can I receive a certificate upon completion of the course?",
      answer:
        "Yes, upon successful completion of the course requirements, you will receive a certificate of completion.",
    },
    {
      question: "How can I contact in case of any questions or assistance? ",
      answer: `You can email us at <a href="mailto:Info@TheLearningExpressAR.org" className='link'>Info@TheLearningExpressAR.org</a> and we will get back to you shortly.`,
    },
  ];

  useEffect(() => {
    // Fetch initial data
    GetStarted()
      .then((res) => {
        setGetStarted(res?.data?.data[0]?.file_name);
      })
      .catch((error) => {
        console.log("GetStarted error:", error);
        
      });

    // Fetch About Us data
    GetAboutByUser()
      .then((res) => {
        if (res.data && res.data.data && res.data.data.length > 0) {
          const imageUrl = res.data.data[0].file_name;
          setAboutData(imageUrl);
        }
      })
      .catch((error) => {
        console.log("GetAboutByUser error:", error);
      });

    // Fetch mission and vision data
    GetMissionVisionByUser()
      .then((res) => {
        if (
          res.data &&
          Array.isArray(res.data.data) &&
          res.data.data.length > 0
        ) {
          const mission = res.data.data.find((item) => item.name === "Mission");
          const vision = res.data.data.find((item) => item.name === "Vision");
          setMissionData(mission);
          setVisionData(vision);
        }
      })
      .catch((error) => {
        console.log("GetMissionVisionByUser error:", error);
      });

    // Fetch all calculators data
    GetAllCalculatorDescription()
      .then((res) => {
        if (
          res?.data &&
          Array.isArray(res?.data?.data) &&
          res?.data?.data?.length > 0
        ) {
          // Find specific calculators
          const savingCalc = res?.data?.data?.find(
            (item) => item.name === "Saving Calculator"
          );
          const mortgageCalc = res?.data?.data?.find(
            (item) => item.name === "Mortgage calculator"
          );
          const budgetCalc = res?.data?.data?.find(
            (item) => item.name === "Budget calculator"
          );
          const debtCalc = res?.data?.data?.find(
            (item) => item.name === "Debt Calculator"
          );

          // Set state for individual calculators
          setSavingCalculator(savingCalc);
          setMortgageCalculator(mortgageCalc);
          setBudgetCalculator(budgetCalc);
          setDebtCalculator(debtCalc);
        }
      })
      .catch((error) => {
        console.log("GetAllCalculatorDescription error:", error);
      });

    // Fetch journey data
    GetJourneyByUser()
      .then((res) => {
        if (res.data && res.data.data.length > 0) {
          setJourneyData(res.data.data[0]); // Assuming you want the first item
        }
      })
      .catch((error) => {
        console.log("GetJourneyByUser error:", error);
      });
      window.scrollTo({ top: 0, left: 0, behavior: "instant" })
  }, []);

  return (
    <div className="landing-page">
      <div>
        <img src={HeaderSection} alt="Header" width="100%" />
      </div>
      <div className="section1">
        <Container>
          <h1>About Us</h1>
          <p className="para">
            The journey began with a simple mission and now we are breaking
            ground to become the go-to resource for personal finance education.
            The fuel of passion for finance and education has now become a
            desire, a larger goal - to bridge the gap between complex financial
            concepts and everyday people.
          </p>
          <p className="para">
            To start with, we expanded our offerings to include a diverse range
            of courses and resources, covering everything from basic budgeting
            to advanced investment strategies. We've always aimed to stay ahead
            of the curve, continuously updating our content to reflect the
            latest trends and technologies in the financial world.
          </p>
          {aboutData ? (
            <img
              src={`${ImageUrl}/${aboutData}`}
              alt="About Us"
              className="aboutImage"
            />
          ) : (
            <Skeleton.Image active className="aboutImage" />
          )}
          <p className="para">
            At The Learning Express, we're not just about numbers, we're about
            empowerment. We're here to help you understand your money, make
            informed decisions, and ultimately, build a secure and prosperous
            future.
          </p>
        </Container>
      </div>
      <div className="section2">
        <Container>
          <div className="cardContainer">
            <div className="card">
              {missionData ? (
                <img
                  src={`${ImageUrl}/${missionData?.file_name}`}
                  alt="Mission"
                />
              ) : (
                <Skeleton.Image active size={200} className="image-skeleton" />
              )}
              <div className="card_text">
                <h1>Mission</h1>
                <p>
                  At The Learning Express, our mission is clear and unwavering;
                  we are dedicated to equipping individuals with the knowledge
                  and resources essential for cultivating a positive financial
                  outlook, ultimately fostering self-sufficiency. We firmly
                  believe that financial literacy is the cornerstone of personal
                  empowerment and community prosperity.
                </p>
              </div>
            </div>

            <div className="card">
              {visionData ? (
                <>
                  <img
                    src={`${ImageUrl}/${visionData?.file_name}`}
                    alt="Vision"
                  />
                </>
              ) : (
                <Skeleton.Image active size={200} className="image-skeleton" />
              )}
              <div className="card_text">
                <h1>Vision</h1>
                <p>
                  We want to see a world of financial independence, where
                  everyone can independently understand and manage their overall
                  financial well-being. In this world of financial independence,
                  there is less stress and anxiety due to financial burdens.
                  There is internal peace because we understand our financial
                  future with clear and executable goals. Financial independence
                  is not a one-time achievement, it’s a lifetime commitment to
                  education which will create sustainable lifestyles for
                  generations to come.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="section3">
        {getStarted ? (
          <img
            src={`${ImageUrl}/${getStarted}`}
            className="getStartImage"
            alt="Get Started"
          />
        ) : (
          <Skeleton.Image active className="getStartImage" />
        )}
        <Container>
          <h1>Let’s Get Started</h1>
          <p>
            Join us on our journey to empower and strengthen individuals &
            communities, while shaping a brighter future where financial
            independence is within everyone’s reach. Together, we can make a
            lasting impact and create a world where financial knowledge is
            power, and self-sufficiency is a reality for all. 
          </p>
          {!token && (
            <button className="more" onClick={() => handleRedirection("login")}>
              Know More
            </button>
          )}
        </Container>
      </div>

      <div className="section4">
        <Container>
          <h1>Explore Our Calculators </h1>
          <p className="para">
            Say hello to effortless financial management with our suite of
            calculators! These digital tools are designed to assist you in
            managing your finances effectively. From planning your savings goals
            to estimating your mortgage payments, managing your budget, and
            creating a structured debt repayment plan, we’ve got your back.{" "}
          </p>
          <div className="calculator-grid">
            <div className="zigzagContainer">
              <div className="imageContainer">
                {savingCalculator ? (
                  <img
                    src={`${ImageUrl}/${savingCalculator?.file_name}`}
                    alt="Savings Calculator"
                    className="calcImage"
                  />
                ) : (
                  <Skeleton.Image active className="skeletonImage" />
                )}
              </div>
              <div className="content">
                <h3>Savings calculator</h3>
                <p>
                  A savings calculator is a helpful digital tool for planning
                  and tracking savings goals. It considers factors like initial
                  savings, regular contributions, interest rates, and savings
                  duration. It's great for setting realistic targets, tracking
                  progress, and making smart financial decisions.
                </p>

                <button
                  className="loginBtn"
                  onClick={() => handleRedirection("saving-calculator")}
                >
                  Know More
                </button>
              </div>
            </div>
            <hr className="hrline" />
            <div className="zigzagContainer">
              <div className="content">
                <h3>Mortgage calculator</h3>
                <p>
                  A mortgage calculator is a handy tool for estimating monthly
                  mortgage payments. It considers factors like loan amount,
                  interest rate, loan term, and down payment. It's useful for
                  prospective home buyers to figure out their monthly housing
                  expenses and how much they can afford to borrow.
                </p>
                <button
                  className="loginBtn"
                  onClick={() => handleRedirection("mortgage-calculator")}
                >
                  Know More
                </button>
              </div>
              <div className="imageContainer">
                {mortgageCalculator ? (
                  <img
                    src={`${ImageUrl}/${mortgageCalculator?.file_name}`}
                    className="calcImage"
                    alt="Mortgage Calculator"
                  />
                ) : (
                  <Skeleton.Image active className="skeletonImage" />
                )}
              </div>
            </div>
            <hr className="hrline" />
            <div className="zigzagContainer">
              <div className="imageContainer">
                {budgetCalculator ? (
                  <img
                    src={`${ImageUrl}/${budgetCalculator?.file_name}`}
                    className="calcImage"
                    alt="Budget Calculator"
                  />
                ) : (
                  <Skeleton.Image active className="skeletonImage" />
                )}
              </div>
              <div className="content">
                <h3>Budget calculator</h3>
                <p>
                  A budget calculator is a tool that helps people manage their
                  money by tracking income, expenses, and savings goals. It's
                  useful for creating a budget plan, monitoring financial
                  health, and making informed decisions to reach financial
                  goals.
                </p>
                <button
                  className="loginBtn"
                  onClick={() => handleRedirection("budget-calculator")}
                >
                  Know More
                </button>
              </div>
            </div>
            <hr className="hrline" />
            <div className="zigzagContainer">
              <div className="content">
                <h3>Debt Calculator</h3>
                <p>
                  A debt calculator is a handy tool for planning and managing
                  debt repayment. Debt calculators help create structured plans
                  to pay off debt, leading to financial freedom and stability.
                </p>
                <button
                  className="loginBtn"
                  onClick={() => handleRedirection("debt-calculator")}
                >
                  Know More
                </button>
              </div>
              <div className="imageContainer">
                {debtCalculator ? (
                  <img
                    src={`${ImageUrl}/${debtCalculator?.file_name}`}
                    className="calcImage"
                    alt="Debt Calculator"
                  />
                ) : (
                  <Skeleton.Image active size={500} className="skeletonImage" />
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="banner-container">
        {journeyData ? (
          <img
            src={`${ImageUrl}/${journeyData?.file_name}`}
            className="joinUsImage"
            alt="Journey"
          />
        ) : (
          <Skeleton.Image active className="joinUsImage" />
        )}
        <Container className="banner_containermax">
          <div className="overlay-text">
            <h1 className="landingHeading">
              Join us on this journey to financial independence your future self
              will thank you!
            </h1>
            <p style={{ marginBottom: "2px" }}>
              Let's get financially fit together!
            </p>
            <p style={{ marginBottom: "2px" }}>
              Let’s turn pennies into power, change into chances and dollars
              into dreams.
            </p>
            <p>
              Financial literacy isn't just a skill, it's a superpower. And it's
              one we're committed to sharing.
            </p>
            {!token && (
              <button
                className="startBtn"
                onClick={() => handleRedirection("login")}
              >
                Let's Start
              </button>
            )}
          </div>
        </Container>
      </div>
      <div className="section5">
        <Container>
          <h1>Some Frequently Asked Questions. </h1>
          <div className="section5_main">
            <Accordion>
              {faq.map((item, index) => (
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header
                    style={{ fontSize: "16px", fontWeight: 600 }}
                  >
                    {item.question}
                  </Accordion.Header>
                  <Accordion.Body
                    dangerouslySetInnerHTML={{ __html: item?.answer }}
                  />
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default NewLandingPage;
