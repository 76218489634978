import React, { useState } from 'react';
import "./ContactUs.scss";
import Image from "../../images/contact.jpg";
import { Button, Form, Input, message} from 'antd';
import { Container, Spinner } from 'react-bootstrap';
import { useForm } from 'antd/lib/form/Form';
import { ContactUsApi } from '../../Common/Api';

const ContactUs = () => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false); // Loading state

  const onFinish = async (values) => {
    setLoading(true); // Start loading
    try {
      const res = await ContactUsApi(values);
      message.success(res?.data?.message);
      form.resetFields();
    } catch (e) {
      console.log(e, "e");
      message.error("Failed to submit the form. Please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  const handleKeyPress = (event) => {
    const char = String.fromCharCode(event.which);
    if (!/[\d.]/.test(char) && !(event.which === 8 || event.which === 0)) {
      event.preventDefault();
    }
  };

  return (
    <div className='ContactUs'>
      <Container>
        <div className='Contact_us_main'>
          <div className='left'>
            <img src={Image} alt="" />
          </div>
          <div className='right'>
            <h2>How can we help?</h2>
            <Form
              form={form}
              name="basic"
              initialValues={{
                remember: true,
              }}
              layout='vertical'
              autoComplete="off"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your name!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email_id"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please input a valid email!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="mobile_number"
                rules={[
                  {
                    required: true,
                    message: 'Please input your phone number!',
                  },
                ]}
              >
                 <Input  onKeyPress={handleKeyPress} />
              </Form.Item>
              <Form.Item
                label="Query"
                name="query"
                rules={[
                  {
                    required: true,
                    message: 'Please input your query!',
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item>
                <Button
                  className='button_theme' 
                  type="primary" 
                  htmlType="submit" 
                  loading={loading} // Show spinner when loading
                  disabled={loading} // Disable button while loading
                >
                  Submit
                  {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    style={{ marginLeft: "10px" }}
                  />
                )}{" "}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContactUs;
