// slices/courseSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetCourseById } from '../Api';


export const fetchCourseById = createAsyncThunk(
  'course/fetchById',
  async ({id,token }) => {
    const response = await GetCourseById(id, token);
    return response.data.data;
  }
);

const courseSlice = createSlice({
  name: 'course',
  initialState: {
    course: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourseById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCourseById.fulfilled, (state, action) => {
        state.loading = false;
        state.course = action.payload;
        state.error = null;
      })
      .addCase(fetchCourseById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default courseSlice.reducer;
