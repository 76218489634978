import React from "react";
import "./DeleteAccount.scss";
import { Container } from "react-bootstrap";

const DeleteAccount = () => {
  return (
    <div className="deleteAccount">
      <Container className="deleteAccountContainer">
        <h3 className="heading">
          How to Delete Your Account on The Learning Express
        </h3>
        <p className="paragraph">
          We’re sorry to see you go! If you need to delete your account, please
          follow the steps below to ensure your account and all associated data
          are removed from The Learning Express. This process is designed to be
          simple and straightforward, ensuring your data privacy and security.
        </p>
        <h4 className="heading">Step-by-Step Instructions</h4>
        <ol className="orderedList">
          <li className="listItem">
            <strong>Download The Learning Express App</strong>
            <ul className="nestedList">
              <li>
                <p className="paragraph">
                  If you haven't already, download The Learning Express app from
                  the App Store or Google Play Store.
                </p>
              </li>
              <li>
                <p className="paragraph">Install the app on your device.</p>
              </li>
            </ul>
          </li>
          <li className="listItem">
            <strong>Log In to Your Account</strong>
            <ul className="nestedList">
              <li>
                <p className="paragraph">
                  Open The Learning Express app on your device.
                </p>
              </li>
              <li>
                <p className="paragraph">
                  Enter your login credentials (email and password) and tap 'Log
                  In'.
                </p>
              </li>
              <li>
                <p className="paragraph">
                  If you’ve forgotten your password, use the 'Forgot Password'
                  option to reset it.
                </p>
              </li>
            </ul>
          </li>
          <li className="listItem">
            <strong>Open the Hamburger Menu</strong>
            <ul className="nestedList">
              <li>
                <p className="paragraph">
                  Once logged in, look for the hamburger menu icon (three horizontal
                  lines) typically located in the top-left or top-right corner of
                  the app screen.
                </p>
              </li>
              <li>
                <p className="paragraph">
                  Tap on the hamburger menu to open the navigation panel.
                </p>
              </li>
            </ul>
          </li>
          <li className="listItem">
            <strong>Go to Settings</strong>
            <ul className="nestedList">
              <li>
                <p className="paragraph">
                  In the navigation panel, scroll down and find the 'Settings'
                  option.
                </p>
              </li>
              <li>
                <p className="paragraph">
                  Tap on 'Settings' to open the settings page.
                </p>
              </li>
            </ul>
          </li>
          <li className="listItem">
            <strong>Click on Delete Account</strong>
            <ul className="nestedList">
              <li>
                <p className="paragraph">
                  On the settings page, scroll down until you see the 'Delete
                  Account' option.
                </p>
              </li>
              <li>
                <p className="paragraph">
                  Tap on 'Delete Account'. You may be asked to re-enter your
                  password for security purposes.
                </p>
              </li>
            </ul>
          </li>
          <li className="listItem">
            <strong>Confirm Account Deletion</strong>
            <ul className="nestedList">
              <li>
                <p className="paragraph">
                  A confirmation message will appear asking if you’re sure you want
                  to delete your account.
                </p>
              </li>
              <li>
                <p className="paragraph">
                  Read the message carefully. If you’re sure about deleting your
                  account, tap 'Yes' to confirm.
                </p>
              </li>
              <li>
                <p className="important">
                  Please note that this action is irreversible. Once you confirm,
                  all your data associated with The Learning Express will be
                  permanently deleted.
                </p>
              </li>
            </ul>
          </li>
        </ol>
        <h4 className="heading">What Happens Next?</h4>
        <p className="paragraph">
          Once you confirm the deletion, your account and all associated data
          will be permanently removed from our servers. This includes any saved
          calculations, course progress, and personal information. You will
          receive a confirmation email once the process is complete.
        </p>
        <h4 className="heading">Need Help?</h4>
        <p className="paragraph">
          If you encounter any issues or have any questions regarding the
          deletion process, please reach out to our support team at{" "}
          <a href="mailto:expressthelearning@gmail.com">
            expressthelearning@gmail.com
          </a>
          . We’re here to help and ensure you have a smooth experience.
        </p>
        <p className="paragraph">
          Thank you for using The Learning Express. We hope to see you again in
          the future!
        </p>
      </Container>
    </div>
  );
};

export default DeleteAccount;
