import React from "react";
import "./MobileScreen.scss";
import Logo from "../images/Logo.png";
import { Link } from "react-router-dom";
import "./Button.scss";

const MobileScreen = () => {
  return (
    <div className="MobileScreen">
      <img src={Logo} alt="Logo" className="logo" />
      <h2>For Better Experience 
        <br/>Download our
        <br/> mobile Apps</h2>
      <center>
        <div className="btn-section">
          <Link
            to= "https://play.google.com/store/apps/details?id=com.taxgruop" 
            target="_blank"
            className="market-btn google-btn"
            role="button"
          >
            <span className="market-button-subtitle">GET IN ON</span>
            <span className="market-button-title">Google Play</span>
          </Link>
        </div>
        <div className="btn-section">
          <Link
            to= "https://apps.apple.com/in/app/the-learning-express/id6473642217"
            target="_blank"
            className="market-btn apple-btn"
            role="button"
          >
            <span className="market-button-subtitle">Download on the</span>
            <span className="market-button-title">App Store</span>
          </Link>
        </div>
      </center>
    </div>
  );
};

export default MobileScreen;
