import React, { useState } from "react";
import SignupImage from "../../images/Signup.jpg";
import { Form, Input, message,Button } from "antd";
import {  Container, Spinner } from "react-bootstrap";
import "./Signup.scss";
import { SendEmailApi, VerifyOtpAPI, createUserAPI } from "../../Common/Api";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerify, setOtpVerify] = useState(false);
  const [showAccountFields, setShowAccountFields] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const onFinish = async (values) => {
    setLoading(true); // Start loading
    try {
      if (otpVerify) {
        // Create user
        await createUserAPI(
          values?.name,
          email,
          values?.mobile,
          values?.password,
          values?.confirmPassword
        );
        message.success("Account created successfully");
        navigate("/login");
      localStorage.removeItem("Userlogintoken")

      } else if (otpSent) {
        // Verify OTP
        const { otp } = values;
        await VerifyOtpAPI(email, otp);
        message.success("OTP verified successfully");
        setOtpVerify(true);
        setOtpSent(true);
        setShowAccountFields(true);
      } else {
        // Send OTP
        await SendEmailApi(values.useremail, "Register");
        setEmail(values.useremail);
        
        setOtpSent(true);
        message.success("OTP sent to your email. Please check and verify.");
      }
    } catch (error) {
message.error(error?.response?.data?.message)
      console.error("Failed:", error?.response?.data?.message);
     
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleRedirection = (path) => {
    navigate(`/${path}`);
  };

  return (
    <div className="Signup">
      <Container>
        <div className="SignupMain">
          <div className="left">
            <img src={SignupImage} alt="" />
          </div>
          <div className="right">
            <h1>Sign Up</h1>
            <Form
              name="basic"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="RightForm"
            >
              {!otpSent && !otpVerify && (
                <Form.Item
                  label="Email"
                  name="useremail"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please input a valid email!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
              {otpSent && !showAccountFields && (
                <Form.Item
                  label="OTP"
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: "Please input the OTP!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
              {showAccountFields && (
                <>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your name!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Mobile Number"
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        message: "Please input your mobile number!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("The two passwords do not match!")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </>
              )}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="button_theme button_theme_antd"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner as="span" animation="border" size="sm" />
                  ) : otpSent ? (
                    showAccountFields ? (
                      "Create Account"
                    ) : (
                      "Verify OTP"
                    )
                  ) : (
                    "Send OTP"
                  )}
                </Button>
              </Form.Item>
            </Form>
            <div className="SignupText">
              <p>
                Already have an account?{" "}
                <span onClick={() => handleRedirection("login")}>Login</span>
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Signup;
