import React from 'react'
import "./CalculatorSection.scss"
import SavingCalculator from "../../images/landing/Savings Calculator.jpg"
import bugetImg from "../../images/landing/bugetImg.png";
import deptImg from "../../images/landing/deptImg.png";
import mortgageImg from "../../images/landing/mortgageImg.png";
import { Container } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import Login from '../Login/Login'
import { getLocalStorageItem } from '../../Common/Api';
const CalculatorSection = ({ children }) => {
    const navigate = useNavigate()
    const location = useLocation();
    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };
    // const token = localStorage.getItem("Userlogintoken")
    const token=getLocalStorageItem("Userlogintoken")
    return (
        <>
            {token !== null ?
                <div className='CalculatorSection'>
                    <Container>
                        <div className='calculator_main'>
                            <div className={`calculator ${isActive('/saving-calculator')}`} onClick={() => navigate("/saving-calculator")}>
                                <img src={SavingCalculator} alt='SavingCalculator' className='calculatorImage' />
                                <span>
                                    Savings calculator
                                </span>
                            </div>
                            <div className={`calculator ${isActive('/mortgage-calculator')}`} onClick={() => navigate("/mortgage-calculator")}>
                                <img src={mortgageImg} alt='SavingCalculator' className='calculatorImage' />
                                <span>
                                    Mortgage calculator
                                </span>
                            </div>
                            <div className={`calculator ${isActive('/debt-calculator')}`} onClick={() => navigate("/debt-calculator")}>
                                <img src={deptImg} alt='SavingCalculator' className='calculatorImage' />
                                <span>
                                    Debt calculator
                                </span>
                            </div>
                            <div className={`calculator ${isActive('/budget-calculator')}`} onClick={() => navigate("/budget-calculator")}>
                                <img src={bugetImg} alt='SavingCalculator' className='calculatorImage' />
                                <span>
                                    Budget calculator
                                </span>
                            </div>
                        </div>
                        <div className='CalculatorContent'>
                            {children}
                        </div>
                    </Container>
                </div> : <Login />}
        </>
    )
}
export default CalculatorSection
