import React, { useState } from "react";
import LoginImage from "../../images/Signup.jpg";
import { Button, Form, Input, message } from "antd";
import { Container, Spinner } from "react-bootstrap";
import "./Login.scss";
import { useNavigate } from "react-router-dom";
import { LoginAPI } from "../../Common/Api";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Loading state

  const onFinish = async (values) => {
    setLoading(true); // Start loading
    try {
      const res = await LoginAPI(values.useremail, values.password);
      const token = res?.data?.data?.user?.tokens?.access?.token;

      if (token) {
        localStorage.setItem("Userlogintoken", token);
        message.success("Congratulations! You've successfully logged in");
        navigate("/home");
      } else {
        message.error("Login failed. Please try again.");
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || "An unexpected error occurred."
      );
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleRedirection = (path) => {
    navigate(`/${path}`);
  };

  return (
    <div className="Login">
      <Container>
        <div className="loginMain">
          <div className="left">
            <img src={LoginImage} alt="Login" />
          </div>
          <div className="right">
            <h1 className="loginHeading">Sign In</h1>
            <Form
              name="basic"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="RightForm"
            >
              <Form.Item
                label="User Email"
                name="useremail"
                rules={[
                  { required: true, message: "Please input your useremail!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <div className="bottom_text">
                <p onClick={() => navigate("/forgot-password")}>
                  Forgot Password
                </p>
              </div>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="button_theme button_theme_antd"
                  disabled={loading} // Disable while loading
                >
                  Submit
                  {loading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      style={{ marginLeft: "10px" }}
                    />
                  )}{" "}
                </Button>
              </Form.Item>
            </Form>
            <div className="SignupText">
              <p>
                I’m a new user?{" "}
                <span onClick={() => handleRedirection("signup")}>Sign Up</span>
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Login;
