import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetProfileTokenAPI } from '../../Common/Api';

export const fetchUserProfile = createAsyncThunk('user/fetchProfile', async (token) => {
  const response = await GetProfileTokenAPI(token);
  return response.data.data;
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    profile: null,
    status: 'idle',
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.status = 'succeeded';
      });
  },
});

export default userSlice.reducer;
