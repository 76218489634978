import React from "react";
import { Route, Routes } from "react-router-dom";
import NewLandingPage from "./Component/NewLandingPage/NewLandingPage";
import CalculatorSection from "./Component/CalculatorSection/CalculatorSection";
import SavingCalculator from "./Component/SavingCalculator/SavingCalculator";
import MortgageCalculator from "./Component/MortgageCalculator/MortgageCalculator";
import BudgetCalculator from "./Component/BudgetCalculator/BudgetCalculator";
import DebtCalculator from "./Component/DebtCalculator/DebtCalculator";
import Course from "./Component/Course/Course";
import CourseDetails from "./Component/CoursesDeatails/CourseDetails";
import CourseLearn from "./Component/CourseLearn/CourseLearn";
import Login from "./Component/Login/Login";
import Signup from "./Component/Signup/Signup";
import CalculatorHistory from "./Component/CalculatorHistory/CalculatorHistory";
import Forum from "./Component/Forum/Forum";
import ForgotPasswordForm from "./Component/ForgotPasswordForm/ForgotPasswordForm";
import ContactUs from "./Component/ContactUs/ContactUs";
import ProfileComponent from "./Component/ProfileComponent/ProfileComponent";
import PrivacyPolicy from "./Component/PrivacyPolicy/Privacy";
import DeleteAccount from "./Component/DeleteAccount/DeleteAccount";
const WebRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<NewLandingPage />} />
      <Route exact path="/home" element={<NewLandingPage />} />
      <Route exact path="/course" element={<Course />} />
      <Route exact path="/coursedetails/:id" element={<CourseDetails />} />
      <Route exact path="/coursesection/:id" element={<CourseLearn />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/signup" element={<Signup />} />
      <Route
        exact
        path="/calculation-history"
        element={<CalculatorHistory />}
      />
      <Route exact path="/forum" element={<Forum />} />
      <Route exact path="/forgot-password" element={<ForgotPasswordForm />} />
      <Route exact path="/profile-edit" element={<ProfileComponent />} />
      <Route exact path="/Contact-us" element={<ContactUs />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/how-to-delete-account" element={<DeleteAccount />} />

      <Route
        exact
        path="/saving-calculator"
        element={
          <CalculatorSection>
            <SavingCalculator />
          </CalculatorSection>
        }
      />
      <Route
        exact
        path="/mortgage-calculator"
        element={
          <CalculatorSection>
            <MortgageCalculator />
          </CalculatorSection>
        }
      />
      <Route
        exact
        path="/budget-calculator"
        element={
          <CalculatorSection>
            <BudgetCalculator />
          </CalculatorSection>
        }
      />
      <Route
        exact
        path="/debt-calculator"
        element={
          <CalculatorSection>
            <DebtCalculator />
          </CalculatorSection>
        }
      />
    </Routes>
  );
};
export default WebRouter;
