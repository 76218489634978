import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  GetAllQuestion,
  GetAllUserQuestion,
  SendQuestionsApi,
} from "../../Common/Api";

// Thunk to fetch all questions
export const fetchAllQuestions = createAsyncThunk(
  "questions/fetchAll",
  async (token, { rejectWithValue }) => {
    try {
      const response = await GetAllQuestion(token);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk to fetch all user-specific questions
// export const fetchAllUserQuestions = createAsyncThunk(
//   "questions/fetchAllUser",
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await GetAllUserQuestion();
//       return response.data.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// Thunk to send a new question
export const sendQuestion = createAsyncThunk(
  "questions/send",
  async ({ question, token }, { rejectWithValue }) => {
    try {
      const response = await SendQuestionsApi(question, token);
      return response.data;
    } catch (error) {
      
      return rejectWithValue(error.response.data);
    }
  }
);

// Initial state
const initialState = {
  questions: [],
  fetchStatus: "idle", // "idle" | "loading" | "succeeded" | "failed"
  sendStatus: "idle",
  error: null,
};

// Slice
const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchAllQuestions cases
      .addCase(fetchAllQuestions.pending, (state) => {
        state.fetchStatus = "loading";
        state.error = null;
      })
      .addCase(fetchAllQuestions.fulfilled, (state, action) => {
        state.questions = action.payload;
        state.fetchStatus = "succeeded";
      })
      .addCase(fetchAllQuestions.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.error = action.payload;
      })

      // Handle fetchAllUserQuestions cases
      // .addCase(fetchAllUserQuestions.pending, (state) => {
      //   state.fetchStatus = "loading";
      //   state.error = null;
      // })
      // .addCase(fetchAllUserQuestions.fulfilled, (state, action) => {
      //   state.questions = action.payload;
      //   state.fetchStatus = "succeeded";
      // })
      // .addCase(fetchAllUserQuestions.rejected, (state, action) => {
      //   state.fetchStatus = "failed";
      //   state.error = action.payload;
      // })

      // Handle sendQuestion cases
      .addCase(sendQuestion.pending, (state) => {
        state.sendStatus = "loading";
        state.error = null;
      })
      .addCase(sendQuestion.fulfilled, (state, action) => {
        state.questions.push(action.payload);
        state.sendStatus = "succeeded";
      })
      .addCase(sendQuestion.rejected, (state, action) => {
        state.sendStatus = "failed";
        state.error = action.payload;
      });
  },
});

// Export the reducer
export default questionsSlice.reducer;
