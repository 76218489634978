import React, { useEffect, useState } from "react";
import "./DebtCalculator.scss";
import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
} from "antd";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { CalculatorApi } from "../../Common/Api";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
const { Option } = Select;
const DebtCalculator = () => {
  const [debtCount, setDebtCount] = useState(1);
  const [showInfo, setShowInfo] = useState(false);
  const [responsedebtData, setResponseDebtDAta] = useState(null); // State to hold response data
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // State for loading spinner

  // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEsImlhdCI6MTcxNDk4NTk1MiwiZXhwIjoxNzE3NTc3OTUyLCJ0eXBlIjoic2Vzc2lvbiJ9.rJ-Ksf4xe0ofkqn6e2Z2seb6M3aoikFI0wNsd3rg3NM"
  const token = localStorage.getItem("Userlogintoken");
  useEffect(() => {
    if (!token) {
      navigate("/login");
      localStorage.removeItem("Userlogintoken")

    }
  }, [navigate, token]);
  const handleInfoClick = () => {
    setShowInfo(!showInfo);
  };
  const handleAddDebt = () => {
    setDebtCount(debtCount + 1);
  };
  const handleRemoveDebt = () => {
    setDebtCount(debtCount - 1);
  };
  const handleResult = (values) => {
    // Construct the response object
    const debtData = [...Array(debtCount)].map((_, index) => ({
      debtType: values[`debtType${index + 1}`],
      balanceOwed: parseFloat(values[`balance${index + 1}`]),
      interestRate: parseFloat(values[`rateOfInterest${index + 1}`]),
      minimumPayment: parseFloat(values[`minimumPayment${index + 1}`]),
    }));
    const response = {
      calculatorType: "DEBT",
      debt: debtData,
      monthlyHouseholdIncome: parseFloat(values.monthlyHouseholdIncome),
      additionalPayment: parseFloat(values.additionalPayment),
    };
    setLoading(true); // Show spinner when starting the API request

    CalculatorApi(response, token)
      .then((response) => {
        setResponseDebtDAta(response.data.calculatorOutput);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.error("Cal Error--", error);
        if (error?.response?.data?.message==="User Not Found") {
          navigate("/login");
      localStorage.removeItem("Userlogintoken")

        }
      })
      .finally(() => {
        setLoading(false); // Hide spinner after request completion
      });
  };

  const handleKeyPress = (event) => {
    const char = String.fromCharCode(event.which);
    if (!/[\d.]/.test(char) && !(event.which === 8 || event.which === 0)) {
      event.preventDefault();
    }
  };
  return (
    <div className="DebtCalculator">
      <h2>Debt Calculator</h2>
      <div className="DebtCalculator_Main">
        <div className="DebtCalculator_Calculation">
          <Form
            name="basic"
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
            onFinish={handleResult}
          >
            {[...Array(debtCount)].map((_, index) => (
              <div key={index}>
                <h5>Debt {index + 1}</h5>
                <Space>
                  <Form.Item
                    label="Debt Type"
                    name={`debtType${index + 1}`}
                    rules={[
                      { required: true, message: "Please select Debt Type!" },
                    ]}
                  >
                    <Select>
                      <Option value="Car Loan">Car Loan</Option>
                      <Option value="Credit Card">Credit Card</Option>
                      <Option value="Student Loan">Student Loan</Option>
                      <Option value="Other Non-Mortgage Debt">
                        Other Non-Mortgage Debt
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Balance"
                    name={`balance${index + 1}`}
                    rules={[
                      { required: true, message: "Please enter Balance!" },
                    ]}
                  >
                    <Input className="inputStyle" onKeyPress={handleKeyPress} />
                  </Form.Item>
                </Space>
                <Space>
                  <Form.Item
                    label="Rate of Interest"
                    name={`rateOfInterest${index + 1}`}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Rate of Interest!",
                      },
                    ]}
                  >
                    <Input className="inputStyle" onKeyPress={handleKeyPress} />
                  </Form.Item>
                  <Form.Item
                    label={
                      <>
                        <span>Minimum Payment</span>
                        <Tooltip title="If your minimum payment doesn't cover your monthly interest, it's too small and you'll be paying this thing off forever. Make sure the minimum covers your monthly interest AND some of the principal.">
                          <BsFillQuestionCircleFill
                            onClick={handleInfoClick}
                            className="icon_info"
                          />
                        </Tooltip>
                      </>
                    }
                    name={`minimumPayment${index + 1}`}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Minimum Payment!",
                      },
                    ]}
                  >
                    <Input className="inputStyle" onKeyPress={handleKeyPress} />
                  </Form.Item>
                </Space>
              </div>
            ))}
            <div className="add_on_debt">
              <Button className="add_debt_btn" onClick={handleAddDebt}>
                <FaPlus color="black" size={13} />
                Add Debt
              </Button>
              {debtCount >= 2 && (
                <Button className="add_debt_btn" onClick={handleRemoveDebt}>
                  <FaMinus color="black" size={13} /> Remove Debt
                </Button>
              )}
            </div>
            <Form.Item
              // label="Monthly Household Income"
              label={
                <>
                  <span>Monthly Household Income</span>
                  <Tooltip title="This includes any income you make each month after taxes (your paycheck, your side hustle—it all counts).">
                    <BsFillQuestionCircleFill
                      onClick={handleInfoClick}
                      className="icon_info"
                    />
                  </Tooltip>
                </>
              }
              name="monthlyHouseholdIncome"
              rules={[{ message: "Please enter Additional Payment!" }]}
            >
              <Input className="inputStyle" onKeyPress={handleKeyPress} />
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span>Additional Payment</span>
                  <Tooltip title="Next, to snowball your debt, enter the additional amount you want to pay above the minimum required payment.">
                    <BsFillQuestionCircleFill
                      onClick={handleInfoClick}
                      className="icon_info"
                    />
                  </Tooltip>
                </>
              }
              name="additionalPayment"
              rules={[{ message: "Please enter Additional Payment!" }]}
            >
              <Input className="inputStyle" onKeyPress={handleKeyPress} />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="button_theme button_theme_antd"
              >
                Calculate
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    style={{ marginLeft: "10px" }}
                  />
                )}{" "}
                {/* Display spinner next to button */}
              </Button>
            </Form.Item>
          </Form>
        </div>
        {responsedebtData && (
          <div className="DebtCalculator_Result">
            <div className="gragh">
              <div className="top_heading">
                <div className="total_Saving">
                  <h5>Months to pay off</h5>
                  <p className="total_saving_amount">
                    {responsedebtData?.freeIn}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="total_Saving">
                <p className="total_saving_heading">Your Total Debt</p>
                <p className="total_saving_amount">
                  {responsedebtData?.totalDebt
                    ? `$${Number(responsedebtData?.totalDebt).toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}`
                    : "$0.00"}
                </p>
              </div>
              <div className="total_Saving">
                <p className="total_saving_heading">
                  If you continue making only minimum payments, you’ll be
                  debt-free:
                </p>
                <p className="total_saving_amount">
                  {responsedebtData?.debtFreeDateWithMinimum}
                </p>
              </div>
              {/* <div className='total_Saving'>
              <p className='total_saving_heading'>If you pay more each month, you’ll be debt-free by:</p>
              <p className='total_saving_amount'>{responsedebtData?.debtFreeDateWithAdditional}</p>
            </div> */}
              <hr />
              <h6>Debt BreakDown</h6>
              {responsedebtData?.debtInfo?.map((item, index) => {
                return (
                  <div className="total_Saving" key={index}>
                    <p className="total_saving_heading">{item.debtType}</p>
                    <p className="total_saving_amount">
                      {/* ${item?.balanceOwed} */}
                      {item?.balanceOwed
                        ? `$${Number(item?.balanceOwed).toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}`
                        : "$0.00"}
                    </p>
                  </div>
                );
              })}
              <div className="total_Saving">
                <p className="total_saving_heading">
                  If you will make additional Payment, you’ll be debt-free:
                </p>
                <p className="total_saving_amount">
                  {responsedebtData?.debtFreeDateWithAdditional}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default DebtCalculator;
