import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Forum.scss";
import { Button, Container, Spinner } from "react-bootstrap";
import { Card, Input, message } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ImageUrl } from "../../Common/Host";
import { fetchUserProfile } from "../../Common/Redux/UserSlice";
// import { fetchAllQuestions } from "../../Common/Redux/forumSlice";
import AskQuestion from "../../images/askques.gif";
import {
  GetAllQuestion,
  getLocalStorageItem,
  SendQuestionsApi,
} from "../../Common/Api";
const Forum = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);
  const [forum, setForum] = useState();
  const token = getLocalStorageItem("Userlogintoken");
  useEffect(() => {
    if (!token) {
      navigate("/login");
      localStorage.removeItem("Userlogintoken")

    }
  }, [navigate, token]);
  // const forum = useSelector((state) => state.forum.allQuestions);
  const { profile } = useSelector((state) => state.user);
  const handleAskQuestion = () => {
    if (!token) {
      navigate("/login");
      localStorage.removeItem("Userlogintoken")

      return;
    }
    setLoading(true);
    // dispatch(sendQuestion({ question, token }))
    //   .then((res) => {
    //     message.success(res.payload.message);
    //     dispatch(fetchAllQuestions(token, navigate));
    //     setQuestion("");
    //   })
    //   .catch((error) => {
    //     console.error("Error sending question:", error);
    //     if (error?.response?.data?.message === "User Not Found") {
    //       navigate("/login");
    //     }
    //   })
    //   .finally(() => setLoading(false));
    SendQuestionsApi(question, token)
      .then((res) => {
        // console.log(res, "res");
        message.success(res?.data?.message);

        setLoading(false);
        showQuestions(token);
        setQuestion("");
      })
      .catch((e) => {
        console.log(e, "error");
        if (e?.response?.data?.message === "User Not Found") {
          navigate("/login");
      localStorage.removeItem("Userlogintoken")

        }
      });
  };
  const showQuestions = () => {
    GetAllQuestion(token)
      .then((res) => {
        console.log(res,"==>>")
        setForum(res?.data?.data);
      })
      .catch((e) => {
        console.log(e, "error");
        if (e?.response?.data?.message === "User Not Found") {
          navigate("/login");
      localStorage.removeItem("Userlogintoken")

        }
      });
  };
  useEffect(() => {
    if (token) {
      // dispatch(fetchAllQuestions(token));
      showQuestions(token);
      dispatch(fetchUserProfile(token));
    }
  }, [dispatch, token]);
  // console.log(forum, "check");
  return (
    <div className="Forum">
      <Container>
        <div className="top">
          <div>
            <h3>Forum</h3>
          </div>
          {token && (
            <div>
              <Button className="button_theme" onClick={() => setQuestion("")}>
                Ask Question
              </Button>
            </div>
          )}
        </div>
        <div className="forum_main">
          <div className="Input_box">
            {token && (
              <Card>
                <div className="main_card">
                  <div className="user_image">
                    {profile?.user_attachment?.[0]?.file_name ? (
                      <img
                        src={`${ImageUrl}${profile.user_attachment[0].file_name}`}
                        alt="User"
                      />
                    ) : (
                      <img
                        src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
                        alt="Default User"
                      />
                    )}
                  </div>
                  <div>
                    <Input
                      placeholder="Ask a question"
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                    />
                    <Button
                      className="button_theme"
                      onClick={handleAskQuestion}
                      disabled={!question || loading}
                    >
                      Ask Question
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          style={{ marginLeft: "10px" }}
                        />
                      )}
                    </Button>
                  </div>
                </div>
              </Card>
            )}
            <div style={{ textAlign: "center" }}>
              {forum?.length === 0 && (
                <img src={AskQuestion} alt="Ask a question" />
              )}
            </div>
            {forum
              ?.slice()
              ?.reverse()
              ?.map((item, index) => (
                <Card key={index}>
                  <div>
                    <div className="main_card">
                      <div className="user_image">
                        {item?.user_forum?.[0]?.user_attachment?.[0]
                          ?.file_name ? (
                          <img
                            src={`${ImageUrl}${item.user_forum[0].user_attachment[0].file_name}`}
                            alt="User"
                          />
                        ) : (
                          <img
                            src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
                            alt="Default User"
                          />
                        )}
                      </div>
                      <div>
                        <h6>{item.user_forum[0]?.name}</h6>
                      </div>
                    </div>
                    <div className="question_section">
                      <div className="question">
                        <h6
                          dangerouslySetInnerHTML={{ __html: item.question }}
                        ></h6>
                        <h6>{moment(item.created_at).fromNow()}</h6>
                      </div>
                      {item.answer === null ? (
                        <p className="adminres">
                          Please wait for an admin to respond.
                        </p>
                      ) : (
                        <p
                          dangerouslySetInnerHTML={{ __html: item.answer }}
                        ></p>
                      )}
                    </div>
                  </div>
                </Card>
              ))}
            {/* {forum?.map((item,index)=>{
                return(
                  <div>
                    {item?.question}
                  </div>
                )
              })} */}
          </div>
        </div>
      </Container>
    </div>
  );
};
export default Forum;
