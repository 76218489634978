// ratingReviewSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetAllRating_ReviewAPI } from "../Api";

const initialState = {
  reviewsAndRatings: [],
  loading: false,
  error: null,
};

export const getAllRatingReview = createAsyncThunk(
  "getRating",
  async ({ courseId, token }) => {
    try {
      const response = await GetAllRating_ReviewAPI(courseId, token);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

const ratingReviewSlice = createSlice({
  name: "ratingReview",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllRatingReview.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllRatingReview.fulfilled, (state, action) => {
        state.loading = false;
        state.reviewsAndRatings = action.payload;
      })
      .addCase(getAllRatingReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Set a specific error message or handle differently
      });
  },
});

export default ratingReviewSlice.reducer;
