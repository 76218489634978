import React, { useEffect, useState } from "react";
import "./BudgetCalculator.scss";
import { Button, Form, Input, Space, Tooltip } from "antd";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { CalculatorApi, getLocalStorageItem } from "../../Common/Api";
import { PieChart } from "@mui/x-charts";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
const BudgetCalculator = () => {
  // const token = localStorage.getItem("Userlogintoken")
  const navigate = useNavigate();
  const token = getLocalStorageItem("Userlogintoken");
  const [loading, setLoading] = useState(false); // State for loading spinner
  useEffect(() => {
    if (!token) {
      navigate("/login");
      localStorage.removeItem("Userlogintoken");
    }
  }, [navigate, token]);
  const [budgetData, setBudgetData] = useState();
  const [showInfo, setShowInfo] = useState(false);
  const handleInfoClick = () => {
    setShowInfo(!showInfo);
  };
  const handleResult = (values) => {
    const requestData = {
      calculatorType: "BUDGET",
      user_input: {
        monthlyIncome: parseFloat(values.MonthlyIncome), // Convert to float if necessary
        otherIncome: parseFloat(values.OtherIncome),
        mortgage: parseFloat(values.Mortgage),
        rent: parseFloat(values.Rent),
        homeInsurance: parseFloat(values.HomeInsurance),
        repairs: parseFloat(values.Maintenance),
        watergaselectricity: parseFloat(values.Electricity),
        cableTvInternet: parseFloat(values.Internet),
        phoneAndCell: parseFloat(values.PhoneCell),
        hoaFees: parseFloat(values.hoaFees),
        carPayment: parseFloat(values.CarPayment),
        carInsurance: parseFloat(values.CarInsurance),
        gasFuel: parseFloat(values.GasFuel),
        carRepairs: parseFloat(values.CarRepairs),
        schoolSupplies: parseFloat(values.SchoolSupplies),
        studentLoans: parseFloat(values.StudentLoans),
        collegeTuition: parseFloat(values.CollegeTuition),
        groceriesHousehold: parseFloat(values.Household),
        clothing: parseFloat(values.Clothing),
        entertainment: parseFloat(values.Entertainment),
        medical: parseFloat(values.Medical),
        petSupplies: parseFloat(values.PetSupplies),
        otherExpenses: parseFloat(values.OtherExpenses),
        emergencyFund: parseFloat(values.EmergencyFund),
        investments: parseFloat(values.Investments),
        retirement: parseFloat(values.Retirement),
      },
    };
    setLoading(true); // Show spinner when starting the API request
    CalculatorApi(requestData, token)
      .then((response) => {
        setBudgetData(response.data.calculatorOutput);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.error("Cal Error--", error);
        if (error?.response?.data?.message === "User Not Found") {
          localStorage.removeItem("Userlogintoken");
          navigate("/login");
        }
      })
      .finally(() => {
        setLoading(false); // Hide spinner after request completion
      });
  };
  const handleKeyPress = (event) => {
    const char = String.fromCharCode(event.which);
    if (!/[\d.]/.test(char) && !(event.which === 8 || event.which === 0)) {
      event.preventDefault();
    }
  };
  return (
    <div className="BudgetCalculator">
      <h2>Budget Calculator</h2>
      <div className="BudgetCalculator_Main">
        <div className="BudgetCalculator_Calculation">
          <Form
            name="basic"
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
            onFinish={handleResult}
          >
            <Space>
              <Form.Item
                label={
                  <>
                    <span>Monthly income </span>
                    <Tooltip title="A person's or organization's income is the money that they earn or receive, as opposed to the money that they have to spend or pay out.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                name="MonthlyIncome"
                rules={[
                  {
                    required: true,
                    message: "Please enter Monthly income!",
                  },
                ]}
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>Other income </span>
                    <Tooltip title="Other income is income that does not come from a companys main business, such as interest.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    message: "Please enter Other Income!",
                  },
                ]}
                name="OtherIncome"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
            </Space>
            <h5>Housing Expense</h5>
            <Space>
              <Form.Item
                label={
                  <>
                    <span>Mortgage </span>
                    <Tooltip title="MORTGAGE EXPENSE means the monthly payment for mortgage principal and interest as well as any monthly mortgage insurance premium.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter Mortgage",
                  },
                ]}
                name="Mortgage"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>Rent </span>
                    <Tooltip title="Rent expense is the cost a business pays to occupy a property for an office, retail space, storage space, or factory.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter Rent!",
                  },
                ]}
                name="Rent"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item
                label={
                  <>
                    <span>Home Insurance </span>
                    <Tooltip title="Home insurance is an insurance policy that covers the costs and damage to your home or any insured property.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter Home Insurance!",
                  },
                ]}
                name="HomeInsurance"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>Maintenance </span>
                    <Tooltip title="Repairs and maintenance are expenses for normal maintenance and upkeep of capital assets that are necessary to keep the assets in their usual condition.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter Maintenance!",
                  },
                ]}
                name="Maintenance"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item
                label={
                  <>
                    <span>Electricity </span>
                    <Tooltip title="Utilities Expenses are costs incurred by the company for using the services of public utility companies like sewage, electricity, waste disposal, water etc.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                name="Electricity"
                rules={[
                  {
                    // required: true,
                    message: "Please enter Electricity !",
                  },
                ]}
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>Internet </span>
                    <Tooltip title="Monthly cable expenses are a combination of cable television and Internet services.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                name="Internet"
                rules={[
                  {
                    message: "Please enter Internet!",
                  },
                ]}
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item
                label={
                  <>
                    <span>Phone & Cell </span>
                    <Tooltip title="Telephone expense is the cost of all land lines, fax lines, and cell phones during a usage period.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                name="PhoneCell"
                rules={[
                  {
                    message: "Please enter PhoneCell!",
                  },
                ]}
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>HOA Fees </span>
                    <Tooltip title="If your neighborhood has a HOA, you need to make sure you account for your fees in the budget calculator. Have a yard full of pink plastic flamingos because you’ll never answer to a HOA? Leave the box blank.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                name="hoaFees"
                rules={[
                  {
                    message: "Please enter HoaFees!",
                  },
                ]}
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
            </Space>
            <h5>Transportation Expenses</h5>
            <Space>
              <Form.Item
                label={
                  <>
                    <span>Car Payment </span>
                    <Tooltip title="Expressed another way, an automobile loan payment consists of two components: an interest payment and a principal payment. While the loan payment is a cash outflow or an expenditure, only the interest portion is an expense that will be reported on a companys income statement.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter Car Payment",
                  },
                ]}
                name="CarPayment"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>Car Insurance</span>
                    <Tooltip title="Car insurance is an agreement between the insurance company and the car owner wherein, the car owner will pay premiums and the insurance company covers for loss or damage caused to the car">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter Car Insurance!",
                  },
                ]}
                name="CarInsurance"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item
                label={
                  <>
                    <span>Gas/Fuel </span>
                    <Tooltip title="Fuel Expenses means all amounts payable and Fuel Supply Agreements, the Fuel Transportation Agreements and any other agreements entered into by Owner with respect to natural gas, electricity or any other energy requirement and related services for the Facility.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter Gas/Fuel",
                  },
                ]}
                name="GasFuel"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>Car Repairs </span>
                    <Tooltip title="Vehicle maintenance expenses are shown in expenditure side of profit and loss account.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter Car Repairs!",
                  },
                ]}
                name="CarRepairs"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
            </Space>
            <h5>Educational Expenses</h5>
            <Form.Item
              label={
                <>
                  <span>School Supplies </span>
                  <Tooltip title="Supplies expense in accounting refers to the cost of a collection of goods that the company used during a specific reporting period to operate">
                    <BsFillQuestionCircleFill
                      onClick={handleInfoClick}
                      className="icon_info"
                    />
                  </Tooltip>
                </>
              }
              rules={[
                {
                  // required: true,
                  message: "Please enter School Supplies",
                },
              ]}
              name="SchoolSupplies"
            >
              <Input className="inputStyle" onKeyPress={handleKeyPress} />
            </Form.Item>
            <Space>
              <Form.Item
                label={
                  <>
                    <span>Student Loans</span>
                    <Tooltip title="A rate of interest 'per cent per annum', written as p.c.p.a., gives the amount of interest due on every hundred rupees for a period of one year, that is, annually.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter Student Loans",
                  },
                ]}
                name="StudentLoans"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>College Tuition </span>
                    <Tooltip title="The Macaulay duration of a loan is the number of years that pass from the loan draw down date until half of the time-weighted present value of the debt services has been paid.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter College Tuition !",
                  },
                ]}
                name="CollegeTuition"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
            </Space>
            <h5>Monthly Savings</h5>
            <Form.Item
              label={
                <>
                  <span>Emergency Fund </span>
                  <Tooltip title="Supplies expense in accounting refers to the cost of a collection of goods that the company used during a specific reporting period to operate">
                    <BsFillQuestionCircleFill
                      onClick={handleInfoClick}
                      className="icon_info"
                    />
                  </Tooltip>
                </>
              }
              rules={[
                {
                  // required: true,
                  message: "Please enter Emergency Fund",
                },
              ]}
              name="EmergencyFund"
            >
              <Input className="inputStyle" onKeyPress={handleKeyPress} />
            </Form.Item>
            <Space>
              <Form.Item
                label={
                  <>
                    <span>Investments</span>
                    <Tooltip title="A rate of interest 'per cent per annum', written as p.c.p.a., gives the amount of interest due on every hundred rupees for a period of one year, that is, annually.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter Investments",
                  },
                ]}
                name="Investments"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>Retirement </span>
                    <Tooltip title="The Macaulay duration of a loan is the number of years that pass from the loan draw down date until half of the time-weighted present value of the debt services has been paid.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter Retirement!",
                  },
                ]}
                name="Retirement"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
            </Space>
            <h5>Food and Personal</h5>
            <Space>
              <Form.Item
                label={
                  <>
                    <span>Household </span>
                    <Tooltip title="A rate of interest 'per cent per annum', written as p.c.p.a., gives the amount of interest due on every hundred rupees for a period of one year, that is, annually.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter Household",
                  },
                ]}
                name="Household"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>Clothing </span>
                    <Tooltip title="The Macaulay duration of a loan is the number of years that pass from the loan draw down date until half of the time-weighted present value of the debt services has been paid.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter Clothing!",
                  },
                ]}
                name="Clothing"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item
                label={
                  <>
                    <span>Entertainment </span>
                    <Tooltip title="A rate of interest 'per cent per annum', written as p.c.p.a., gives the amount of interest due on every hundred rupees for a period of one year, that is, annually.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter Entertainment",
                  },
                ]}
                name="Entertainment"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>Medical </span>
                    <Tooltip title="The Macaulay duration of a loan is the number of years that pass from the loan draw down date until half of the time-weighted present value of the debt services has been paid.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter Medical!",
                  },
                ]}
                name="Medical"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item
                label={
                  <>
                    <span>Pet Supplies </span>
                    <Tooltip title="A rate of interest 'per cent per annum', written as p.c.p.a., gives the amount of interest due on every hundred rupees for a period of one year, that is, annually.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter Pet Supplies",
                  },
                ]}
                name="PetSupplies"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>Other Expenses </span>
                    <Tooltip title="The Macaulay duration of a loan is the number of years that pass from the loan draw down date until half of the time-weighted present value of the debt services has been paid.">
                      <BsFillQuestionCircleFill
                        onClick={handleInfoClick}
                        className="icon_info"
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    // required: true,
                    message: "Please enter OtherExpenses!",
                  },
                ]}
                name="OtherExpenses"
              >
                <Input className="inputStyle" onKeyPress={handleKeyPress} />
              </Form.Item>
            </Space>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="button_theme button_theme_antd"
              >
                Calculate
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    style={{ marginLeft: "10px" }}
                  />
                )}{" "}
                {/* Display spinner next to button */}
              </Button>
            </Form.Item>
          </Form>
        </div>
        {budgetData && (
          <div>
            <div className="BudgetCalculator_Result">
              <div className="gragh">
                <PieChart
                  series={[
                    {
                      data: [
                        {
                          id: 0,
                          value: budgetData?.monthlyIncome,
                          color: "#FDA757",
                        },
                        {
                          id: 1,
                          value: budgetData?.monthlyExpenses,
                          color: "#FCBA39",
                        },
                        {
                          id: 2,
                          value: budgetData?.netIncome,
                          color: "#F3ED5E",
                        },
                      ],
                    },
                  ]}
                  width={300}
                  height={200}
                />
              </div>
              <div className="values">
                <div className="first_color"></div>
                <div>
                  <p className="vul">Monthly Income</p>
                  <p className="amt">
                    {/* ${budgetData?.monthlyIncome} */}
                    {budgetData?.monthlyIncome
                      ? `$${Number(budgetData?.monthlyIncome).toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}`
                      : "$0.00"}
                  </p>
                </div>
                <div className="second_color"></div>
                <div>
                  <p className="vul">Monthly Expenses</p>
                  <p className="amt">${budgetData?.monthlyExpenses}</p>
                </div>
                <div className="third_color"></div>
                <div>
                  <p className="vul">Net Income</p>
                  <p className="amt">${budgetData?.netIncome}</p>
                </div>
              </div>
            </div>
            <div className="BudgetCalculator_Result">
              <div className="values_display">
                <p className="vul">Monthly Income</p>
                <p className="amt">${budgetData?.monthlyIncome}</p>
              </div>
              <div className="values_display">
                <p className="vul">Housing</p>
                <p className="amt">${budgetData?.housingExpenses}</p>
              </div>
              <div className="values_display">
                <p className="vul">Transportations</p>
                <p className="amt">${budgetData?.transportationExpenses}</p>
              </div>
              <div className="values_display">
                <p className="vul">Educational</p>
                <p className="amt">${budgetData?.educationalExpenses}</p>
              </div>
              <div className="values_display">
                <p className="vul">Personal</p>
                <p className="amt">${budgetData?.personalExpenses}</p>
              </div>
              <div className="values_display">
                <p className="vul">Savings</p>
                <p className="amt">${budgetData?.monthlySavings}</p>
              </div>
              <div className="values_display">
                <p className="vul">Monthly Expenses</p>
                <p className="amt">${budgetData?.monthlyExpenses}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default BudgetCalculator;
