import React, { useState } from "react";
import "./ForgotPasswordForm.scss";
import { Form, Input, message, Button } from "antd";
import { RestPassword, SendEmailApi } from "../../Common/Api";
import { Container, Spinner } from "react-bootstrap"; // Import Spinner
import logo from "../../images/Banner.png";
import { useNavigate } from "react-router-dom";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false); // Loading state for the spinner
  const [currentStep, setCurrentStep] = useState(0);

  const handleSendEmail = (values) => {
    setLoading(true); // Start loading spinner
    SendEmailApi(values.email, "forget_password")
      .then((res) => {
        message.success("OTP sent to your email. Please check and verify.");
        setEmailSent(true);
        setEmail(values.email);
        setCurrentStep(1);
      })
      .catch((e) => {
        message.error(e?.response?.data?.message);
        if (e?.response?.data?.message === "User Not Found") {
          navigate("/login");
          localStorage.removeItem("Userlogintoken");
        }
      })
      .finally(() => {
        setLoading(false); // Stop loading spinner
      });
  };

  const handleResetPassword = (values) => {
    setLoading(true); // Start loading spinner
    const formData = {
      email_id: email,
      otp: values.otp,
      password: values.password,
      confirm_password: values.confirm_password,
    };
    RestPassword(formData)
      .then((res) => {
        message.success("Password has been reset successfully.");
        setCurrentStep(3);
        navigate("/login");
        localStorage.removeItem("Userlogintoken");
      })
      .catch((e) => {
        message.error(e?.response?.data?.message);
        if (e?.response?.data?.message === "User Not Found") {
          navigate("/login");
          localStorage.removeItem("Userlogintoken");
        }

        console.log(e);
      })
      .finally(() => {
        setLoading(false); // Stop loading spinner
      });
  };

  return (
    <div className="Login">
      <Container>
        <div className="loginMain">
          <div className="left">
            <img src={logo} alt="" />
          </div>
          <div className="right">
            <h1>Forgot Your Password?</h1>
            <p className="para">
              Don't worry, we'll help you get back into your account. Enter your
              email address below to reset your password.
            </p>
            <div className="form">
              {currentStep === 0 && (
                <Form onFinish={handleSendEmail} layout="vertical">
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ type: "email", required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      style={{ backgroundColor: "#25B8CB" }}
                      type="primary"
                      htmlType="submit"
                      block
                      disabled={emailSent || loading}
                      className="button_theme"
                    >
                      {loading ? (
                        <>
                          <Spinner as="span" animation="border" size="sm" />{" "}
                          Sending...
                        </>
                      ) : (
                        "Send Email"
                      )}
                    </Button>
                  </Form.Item>
                </Form>
              )}
              {currentStep === 1 && (
                <Form onFinish={handleResetPassword} layout="vertical">
                  <Form.Item
                    name="otp"
                    label="OTP"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[{ required: true }]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    name="confirm_password"
                    label="Confirm Password"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "The two passwords that you entered do not match!"
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="button_theme button_theme_antd"
                      style={{ backgroundColor: "#25B8CB" }}
                      type="primary"
                      htmlType="submit"
                      block
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <Spinner as="span" animation="border" size="sm" />{" "}
                          Resetting...
                        </>
                      ) : (
                        "Reset Password"
                      )}
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ForgotPasswordForm;
