import axios from "axios";
import { jwtDecode } from "jwt-decode";
const BASE_URL = "https://node.thelearningexpressar.org:3000/api/v1/";

// ****************************SendEmail******************

export const getLocalStorageItem = (key) => {
  if (typeof window === "undefined") {
    return null;
  }

  const token = localStorage.getItem(key);
  if (!token) {
    return null;
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    if (decodedToken.exp < currentTime) {
      localStorage.removeItem(key);
      return null;
    }

    return token;
  } catch (error) {
    return null;
  }
};

export const CheckToken = async (token) => {
  return await axios.get(BASE_URL + `user/checkTokenStatus`, {
    headers: {
      "x-access-token": token,
    },
  });
};

export const SendEmailApi = async (value1, value2) => {
  let config = {
    email_id: value1,
    request_type: value2,
  };
  return await axios.post(BASE_URL + "user/sendOTP", config);
};
// ****************************VerfiyOtp******************
export const VerifyOtpAPI = async (value1, value2) => {
  let config = {
    email_id: value1,
    otp: value2,
  };

  return await axios.post(BASE_URL + "user/verifyOTP", config);
};
// ****************************Create user ******************
export const createUserAPI = async (value1, value2, value3, value4, value5) => {
  let config = {
    name: value1,
    email_id: value2,
    mobile_number: value3,
    password: value4,
    confirm_password: value5,
  };
  return await axios.post(BASE_URL + "user/signUp", config, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};
// ****************************Login user ******************
export const LoginAPI = async (value1, value2) => {
  let config = {
    email_id: value1,
    password: value2,
  };
  return await axios.post(BASE_URL + "user/login", config);
};
// ****************************Send Email For Forget Password ******************
export const ForgetPassSendEmail = async (value1) => {
  let config = {
    email_id: value1,
  };
  return await axios.post(BASE_URL + "user/sendOTP/", config);
};
// ****************************Reset Password ******************
export const RestPassword = async (data) => {
  return await axios.post(BASE_URL + "user/forgetPassword", data);
};
// ****************************Calculator api******************
export const CalculatorApi = async (data, token) => {
  const headers = {
    Accept: "application/json",
    "x-access-token": token,
  };
  return await axios.post(BASE_URL + "calculator/calculate", data, { headers });
};
// *******************Get All Cpurses by Token ***************************
export const GetAllCourseAPI = async () => {
  return await axios.get(BASE_URL + "course/getAllCourse");
};

// ***********calcultaor description **************

export const GetCalculatorDescriptionAPI = async () => {
  return await axios.get(BASE_URL + "calculator/getAllCalculatorDescription");
};
//******************@Get Courses by id************************************
export const GetCourseById = async (value, token) => {
  return await axios.get(BASE_URL + `course/getCourseById/${value}`, {
    headers: {
      "x-access-token": token,
    },
  });
};
//****************** getcourseWithout Token ******************
export const GetCourseByIdWithoutToken = async (value) => {
  return await axios.get(BASE_URL + `course/findCourseById/${value}`);
};
//************************ */ @GetProfile by TokenAPI***********************
export const GetProfileTokenAPI = async (token) => {
  return await axios.get(BASE_URL + "user/getUserProfile", {
    headers: {
      "x-access-token": token,
    },
  });
};
//****************** */ @To get the section list of a particular courses section**********
export const CourseSectionList = async (value1, value2, token) => {
  let config = {
    course_id: value1,
    section_id: value2,
  };
  return await axios.post(
    BASE_URL + "course/getSectionContentBySectionId",
    config,
    {
      headers: {
        "x-access-token": token,
      },
    }
  );
};

//******************** */ @Get All Rating & Review Api**************************************
export const GetAllRating_ReviewAPI = async (course_id, token) => {
  let config = {
    course_id: course_id,
  };
  return await axios.post(BASE_URL + "course/getReviewsAndRatings", config, {
    headers: {
      "x-access-token": token,
    },
  });
};
//******************** */ @Get All Rating & Review Without Token Api**************************************
export const GetAllRating_ReviewAPIWithouToken = async (course_id) => {
  let config = {
    course_id: course_id,
  };
  return await axios.post(BASE_URL + "course/getAllReviewsAndRatings", config);
};
//*************** */ @Add Rating & Review Api*************************************
export const AddRatingReviewAPI = async (value1, value2, value3, token) => {
  let config = {
    course_id: value1,
    review: value2,
    rating: value3,
  };
  return await axios.post(BASE_URL + "course/postReviewAndRating", config, {
    headers: {
      "x-access-token": token,
    },
  });
};
//*************** markLectureAsCompletedApi*************************************
export const markLectureAsCompletedApi = async (
  value1,
  value2,
  value3,
  token
) => {
  let config = {
    lecture_id: value1,
    section_id: value2,
    course_id: value3,
  };
  return await axios.post(BASE_URL + "course/markLectureAsCompleted", config, {
    headers: {
      "x-access-token": token,
    },
  });
};
//***************addToWatchList*************************************
export const addCourseToWatchListApi = async (value1, token) => {
  let config = {
    course_id: value1,
  };
  return await axios.post(BASE_URL + "course/addCourseToWatchList", config, {
    headers: {
      "x-access-token": token,
    },
  });
};
//*************** */ get History i*************************************
export const GetHistoryAPI = async (value1, token) => {
  let config = {
    calculatorType: value1,
  };
  return await axios.post(
    BASE_URL + "calculator/getHistoryByCalculatorType",
    config,
    {
      headers: {
        "x-access-token": token,
      },
    }
  );
};
// *************** */  Add WatchList*************************************
export const AddWatchList = async (value1, token) => {
  let config = {
    course_id: value1,
  };
  return await axios.post(BASE_URL + "course/addCourseToWatchlist", config, {
    headers: {
      "x-access-token": token,
    },
  });
};
// *******************Show WatchList ***************************
export const ShowWatchList = async (token) => {
  return await axios.get(BASE_URL + "course/getWatchList", {
    headers: {
      "x-access-token": token,
    },
  });
};

// *******************Update Profile ***************************

export const UpdateProfileAPI = async (name, mobile, token) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("mobile_number", mobile);

  return await axios.post(BASE_URL + "user/updateUser", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "x-access-token": token,
    },
  });
};

export const SendQuestionsApi = async (question, token) => {
  const headers = {
    Accept: "application/json",
    "x-access-token": token,
  };
  return await axios.post(
    BASE_URL + "forum/createQuestion",
    { question },
    { headers }
  );
};
// *******************GetAllQuestion***************************
export const GetAllQuestion = async (token) => {
  const headers = {
    Accept: "application/json",
    "x-access-token": token,
  };
  return await axios.get(BASE_URL + `forum/getAllQuestions`, { headers });
};
// *******************GetAllQuestionforAllUser***************************
// export const GetAllUserQuestion = async () => {
//   return await axios.get(BASE_URL + `forum/getAllQuestionsOfAllUsers`);
// };
// *******************QuestionAnswer particular ID***************************
export const QuestionAnswerByID = async (id, token) => {
  const headers = {
    Accept: "application/json",
    "x-access-token": token,
  };
  return await axios.get(BASE_URL + `forum/getQuestionById?id=${id}`, {
    headers,
  });
};
// *******************All Calculation History ***************************
export const AllHistory = async (token) => {
  const headers = {
    Accept: "application/json",
    "x-access-token": token,
  };
  return await axios.get(BASE_URL + "calculator/getHistoryByCalculatorType", {
    headers,
  });
};
export const ContactUsApi = async (contactUsData) => {
  return await axios.post(BASE_URL + "user/contactForm", contactUsData);
};

export const GetAllProducts = async () => {
  try {
    const res = await axios.get(BASE_URL + "product/getAllProductsByAdmin", {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return res;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};
// *******************Attempt Quiz***************************
export const AttemptQuiz = async (queid, ansid, token) => {
  let config = {
    question_id: queid,
    user_answer_id: ansid,
  };
  const headers = {
    Accept: "application/json",
    "x-access-token": token,
  };
  return await axios.post(BASE_URL + "course/attemptQuizByQuestionId", config, {
    headers,
  });
};

// *******************Submit Quiz***************************
export const SubmitQuiz = async (quiz_id, token) => {
  let config = {
    quiz_id: quiz_id,
  };
  const headers = {
    Accept: "application/json",
    "x-access-token": token,
  };
  return await axios.post(BASE_URL + "course/submitQuiz", config, { headers });
};

// *******************Get Banner***************************
export const GetStarted = async () => {
  return await axios.get(BASE_URL + "/content/getLetsGetStartedByUser");
};
export const GetAllCalculatorDescription = async () => {
  return await axios.get(
    BASE_URL + "/calculator/getAllCalculatorDescriptionByUser"
  );
};
export const GetAboutByUser = async () => {
  return await axios.get(BASE_URL + "/content/getAboutUsByUser");
};
export const GetJourneyByUser = async () => {
  return await axios.get(BASE_URL + "/content/getJourneyByUser");
};
export const GetMissionVisionByUser = async () => {
  return await axios.get(BASE_URL + "/content/getMissionVisionByUser");
};
