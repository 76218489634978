import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Input, message, Space } from "antd";
import "./ProfileComponent.scss";
import Login from "../Login/Login";
import {
  getLocalStorageItem,
  GetProfileTokenAPI,
  UpdateProfileAPI,
} from "../../Common/Api";
import { Container, Spinner } from "react-bootstrap";
import { ImageUrl } from "../../Common/Host";
import { useNavigate } from "react-router-dom";

const ProfileComponent = () => {
  const [form] = Form.useForm();
  const [user, setUser] = useState();
  // const [selectedImage, setSelectedImage] = useState("");
  const [loading, setLoading] = useState(false); // State for loading spinner

  const token = getLocalStorageItem("Userlogintoken");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
      localStorage.removeItem("Userlogintoken");
    }
  }, [navigate, token]);

  const getProfileToken = useCallback(() => {
    if (token) {
      GetProfileTokenAPI(token)
        .then((res) => {
          setUser(res?.data?.data);
          form.setFieldsValue({
            name: res?.data?.data?.name,
            mobile: res?.data?.data?.mobile_number,
            email: res?.data?.data?.email_id,
          });
        })
        .catch((e) => {
          console.log(e);
          message.error("Failed to fetch profile data.");
          if (e?.response?.data?.message === "User Not Found") {
            navigate("/login");
            localStorage.removeItem("Userlogintoken");
          }
        });
    }
  }, [token, form, navigate]);

  useEffect(() => {
    getProfileToken();
  }, [token]);

  const onFinish = async (value) => {
    setLoading(true); // Start loading
    try {
      const res = await UpdateProfileAPI(
        value?.name,
        value?.mobile,
        // selectedImage,
        token
      );
      message.success(res?.data?.message);
      getProfileToken(); // Update the user data after successful update
    } catch (error) {
      console.log(error);
      message.error("Failed to update profile.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <>
      {token ? (
        <div className="ProfileComponent">
          <Container>
            <h1>Profile</h1>
            <div className="Profile_main_component">
              <Form onFinish={onFinish} layout="vertical" form={form}>
                {/* <div className="profile-picture-container">
                  {selectedImage ? (
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      alt="Profile Preview"
                      className="Profile_top_images"
                    />
                  ) : user?.user_attachment[0]?.file_name ? (
                    <img
                      src={`${ImageUrl}${user?.user_attachment[0]?.file_name}`}
                      alt="Profile Pic"
                      className="Profile_top_images"
                      style={{ borderRadius: "50px" }}
                    />
                  ) : (
                    <img
                      src="https://img.freepik.com/premium-vector/anonymous-user-circle-icon-vector-illustration-flat-style-with-long-shadow_520826-1931.jpg?w=360"
                      alt="Default User"
                      className="Profile_top_images"
                      style={{ borderRadius: "100px" }}
                    />
                  )}
                  <label className="custom_file_upload">
                    <input
                      type="file"
                      accept="image/*"
                      name="image"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        setSelectedImage(file);
                      }}
                    />
                    <span style={{ cursor: "pointer" }}>
                      &nbsp;{" "}
                      {selectedImage || user?.user_attachment[0]?.file_name
                        ? "Change"
                        : "Add"}{" "}
                      Profile picture
                    </span>
                  </label>
                </div> */}
                <Space>
                  <Form.Item label="Name" name="name">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Email" name="email">
                    <Input disabled />
                  </Form.Item>
                </Space>
                <Form.Item label="Mobile Number" name="mobile">
                  <Input
                    placeholder={
                      user?.mobile_number || "Enter your mobile number"
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <div className="bUtton_custom">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="button_theme button_theme_antd"
                      disabled={loading} // Disable button during loading
                    >
                      Save
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          style={{ marginLeft: "10px" }}
                        />
                      )}{" "}
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Container>
        </div>
      ) : (
        <Login />
      )}
    </>
  );
};

export default ProfileComponent;
