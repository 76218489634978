// store.js
import { configureStore } from '@reduxjs/toolkit';
import ratingReviewReducer from './ratingSlice';
import forumReducer from "./forumSlice"
import courseReducer from "./courseSlice"
import ratingReviewWithoutTokenReducer from "./withouttokenratingSlice"
import questionsReducer from "./QuestionSlice"
import userReducer from "./UserSlice"
const store = configureStore({
  reducer: {
    ratingReview: ratingReviewReducer,
    forum: forumReducer,
    course: courseReducer,
    ratingReviewWithoutToken: ratingReviewWithoutTokenReducer,
    questions: questionsReducer,
    user: userReducer,

  },
});

export default store;
