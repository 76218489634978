import React, { useState, useEffect } from 'react';
import './Quiz.scss';
import { AttemptQuiz, SubmitQuiz, markLectureAsCompletedApi } from '../../Common/Api';
import { fetchCourseById } from '../../Common/Redux/courseSlice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const Quiz = ({ lectureQuizData, lectureQuizid }) => {
const { id } = useParams()
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [showResult, setShowResult] = useState(false);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  // const [token] = useState(localStorage.getItem("Userlogintoken"));
  const token ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEsImlhdCI6MTcxNDk5NTM1MSwiZXhwIjoxNzE3NTg3MzUxLCJ0eXBlIjoic2Vzc2lvbiJ9.8Ue8AJtE_ZN6auXevjWaySmk-jTdzc2Lt9yqvuap6Nk"
  const [result, setResult] = useState({});
  const [loading, setLoading] = useState(false);
  const [calculatingResult, setCalculatingResult] = useState(false);

  const { question, quiz_answers: choices, id: questionId } = lectureQuizData[activeQuestion];

  useEffect(() => {
    setLoading(false);
  }, [activeQuestion]);

  const onAnswerSelected = (answer, index) => {
    setSelectedAnswerIndex(index);
    setSelectedAnswer(answer);
  };

  const attemptQuiz = (questionId, answerId) => {
    setLoading(true);
    AttemptQuiz(questionId, answerId, token)
      .then((res) => {
        // Handle response if needed
      })
      .catch((error) => {
        // Handle error if needed
      })
      .finally(() => {
        setLoading(false);
      });
  };
const dispatch=useDispatch()

  const finishQuiz = () => {
    setLoading(true);
    markLectureAsCompletedApi(lectureQuizData[0]?.lecture_id, lectureQuizData[0]?.section_id, lectureQuizData[0]?.course_id, token)
      .then(() => {
        dispatch(fetchCourseById({ id, token }));

        setCalculatingResult(true);
        SubmitQuiz(lectureQuizid, token)
          .then((res) => {
            setResult(res?.data?.data);
            setShowResult(true);
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setLoading(false);
            setCalculatingResult(false);
          });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNextOrFinish = () => {
    if (activeQuestion === lectureQuizData.length - 1) {
      finishQuiz();
      attemptQuiz(questionId, selectedAnswer);
    } else {
      attemptQuiz(questionId, selectedAnswer);
      setActiveQuestion(prevQuestion => prevQuestion + 1);
      setSelectedAnswer('');
      setSelectedAnswerIndex(null);
    }
  };

  return (
    <div className='quizModule'>
      <div className="quiz-container">
        {!showResult ? (
          <div>
            <div>
              <span className="active-question-no">{activeQuestion + 1}</span>
              <span className="total-question">/{lectureQuizData.length}</span>
            </div>
            <h2>{question}</h2>
            <ul>
              {choices?.map(({ id, options }, index) => (
                <li
                  onClick={() => onAnswerSelected(options, index)}
                  key={id}
                  className={selectedAnswerIndex === index ? 'selected-answer' : null}
                >
                  {options}
                </li>
              ))}
            </ul>
            <div className="flex-right">
              {activeQuestion === lectureQuizData.length - 1 ?
                <button onClick={handleNextOrFinish} disabled={selectedAnswerIndex === null}>
                  {loading ? 'Loading...' : 'Finish'}
                </button> :
                <button onClick={handleNextOrFinish} disabled={selectedAnswerIndex === null}>
                  {loading ? 'Loading...' : 'Next'}
                </button>
              }
            </div>
          </div>
        ) : (
          <div>
            <div className="result">
              {calculatingResult ? (
                <p>Please wait while calculating your result...</p>
              ) : (
                <>
                  <h3>Result</h3>
                  {Object?.entries(result).map(([key, value]) => (
                    <div key={key}>
                      {key}: {value}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Quiz;
