import "./App.scss";
import HeaderSection from "./Component/HeaderSection/HeaderSection";
import WebRouter from "./WebRouter";
import FooterSection from "./Component/FooterSection/FooterSection";
import MobileScreen from "./MobileScreen/MobileScreen";
function App() {
  return (
    <>
      {/* <HeaderSection />
    <WebRouter/>
    <FooterSection/> 
    */}
      <div className="">
        <div className="desktop">
          <HeaderSection />
          <WebRouter />
          <FooterSection />
        </div>
        <div className="Mobile">
          <MobileScreen/>
          {/* <div
            className="text"
            style={{ position: "absolute", margin: "50px" }}
          >
            <h1>Download the Android version</h1>
            <div className="btnsection">
              <button className="buttonTheme">
                <a
                  style={{ color: "inherit", textDecoration: "none" }}
                  href="https://play.google.com/store/apps/details?id=com.taxgruop"
                >
                  Click me for Android
                </a>
              </button>
              <button className="buttonTheme">
                <a
                  style={{ color: "inherit", textDecoration: "none" }}
                  href="https://apps.apple.com/in/app/the-learning-express/id6473642217"
                >
                  Click me for Apple
                </a>
              </button>
            </div>
          </div>  */}
          {/* <div className="">
            <img src={Banner} width="100%" height="700vh" alt="" />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default App;
