import { createSlice } from "@reduxjs/toolkit";
import { GetAllQuestion, GetProfileTokenAPI } from "../../Common/Api";

const initialState = {
  allQuestions: [],
  filterQuestion: null,
  userDetail: null,
  alert: false,
  alertConfig: {
    text: "",
    icon: "",
  },
  loading: false,
  error: null,
};

const forumSlice = createSlice({
  name: "forum",
  initialState,
  reducers: {
    setAllQuestions(state, action) {
      state.allQuestions = action.payload;
    },
    setFilterQuestion(state, action) {
      state.filterQuestion = action.payload;
    },
    setUserDetail(state, action) {
      state.userDetail = action.payload;
    },
    setAlert(state, action) {
      state.alert = action.payload;
    },
    setAlertConfig(state, action) {
      state.alertConfig = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const {
  setAllQuestions,
  setFilterQuestion,
  setUserDetail,
  setAlert,
  setAlertConfig,
  setLoading,
  setError,
  resetError,
} = forumSlice.actions;

export const fetchAllQuestions = (token, navigate) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(resetError());
  try {
    const response = await GetAllQuestion(token);
    dispatch(setAllQuestions(response.data.data));
  } catch (error) {
    console.error("Error fetching questions:", error);
    if (error?.response?.data?.message === "User Not Found") {
      navigate("/login"); // Redirect to login
    }
    dispatch(setError(error.message || "Failed to fetch questions"));
  } finally {
    dispatch(setLoading(false));
  }
};

// export const fetchAllQuestions = (token, navigate) => async (dispatch) => {
//   dispatch(setLoading(true));
//   dispatch(resetError());
//   try {
//     const response = await GetAllQuestion(token);
//     dispatch(setAllQuestions(response.data.data));
//   } catch (error) {
//     console.error("Error fetching questions:", error);
//     if (error?.response?.data?.message === "User Not Found") {
//       navigate("/login");
//     }
//     dispatch(setError(error.message || "Failed to fetch questions"));
//   } finally {
//     dispatch(setLoading(false));
//   }
// };

export const fetchUserDetail = (token) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(resetError());
  try {
    const response = await GetProfileTokenAPI(token);
    dispatch(setUserDetail(response.data.data));
  } catch (error) {
    console.error("Error fetching user details:", error);
    dispatch(setError(error.message || "Failed to fetch user details"));
  } finally {
    dispatch(setLoading(false));
  }
};

export default forumSlice.reducer;
