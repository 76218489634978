import React, { useEffect, useState } from 'react';
import "./StudentReview.scss";
import { Rating } from '@mui/material';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AddRatingReviewAPI, getLocalStorageItem } from '../../Common/Api';
import DescriptionAlerts from '../../Common/Alert';
import { Rate } from 'antd';
import moment from 'moment';
import { getAllRatingReview } from '../../Common/Redux/ratingSlice';
import { getAllRatingReviewWithoutToken } from '../../Common/Redux/withouttokenratingSlice';
const StudentReview = ({ reviewcheck, courseId }) => {
    
    const token=getLocalStorageItem("Userlogintoken")
    const [review, setReview] = useState('');
    const [rating, setRating] = useState(null);
    const [alert, setAlert] = useState(false);
    const [alertConfig, setAlertConfig] = useState({
        text: "",
    });
    const dispatch = useDispatch();
    const { reviewsAndRatings} = useSelector(state => state.ratingReview);
    useEffect(() => {
        if (token) {
            dispatch(getAllRatingReview({ courseId, token }));
        } else {
            dispatch(getAllRatingReviewWithoutToken({ courseId }));
        }
    }, [dispatch, courseId, token]);
    const handleRatingReviewSubmit = async () => {
        if (!rating || !review) {
            setAlert(true);
            setAlertConfig({
                icon: "error",
                text: "Please provide both rating and review.",
                time: "4000",
            });
            setTimeout(() => setAlert(false), 4000);
            return;
        }
        try {
            const res = await AddRatingReviewAPI(courseId, review, rating, token);
            if (res.status === 201) {
                setAlert(true);
                setAlertConfig({
                    icon: "info",
                    text: res.data.message,
                    time: "4000",
                });
                setTimeout(() => {
                    if (token) {
                        dispatch(getAllRatingReview({ courseId, token }));
                    } else {
                        dispatch(getAllRatingReviewWithoutToken({ courseId }));
                    }
                    setRating(null); // Clear the rating field
                    setReview(''); // Clear the review field
                    setAlert(false);
                }, 5000);
            }
        } catch (e) {
            console.error("Post Rating Error", e);
        }
    };
    return (
        <div className='StudentReview'>
            {alert && (
                <DescriptionAlerts text={alertConfig.text} icon={alertConfig.icon} time={alertConfig.time} />
            )}
            {reviewsAndRatings.length > 0 && (
                <div className='StudentReview_section1'>
                    <h4>Student Reviews</h4>
                    {reviewsAndRatings.map((item, index) => (
                        <div className='section1_main' key={index}>
                            <div className='main'>
                                <div className='LetterName'>
                                    <h5>{item?.review_users[0]?.name?.charAt(0).toUpperCase()}</h5>
                                </div>
                                <div className='Review'>
                                    <h6>{item?.review_users[0]?.name}</h6>
                                    <Rate disabled defaultValue={item?.rating} />
                                    <p>{moment(item?.created_at).fromNow()}</p>
                                    <p>{item?.review}</p>
                                </div>
                            </div>
                            <hr />
                        </div>
                    ))}
                </div>
            )}
            {reviewcheck?.length === 0 &&
                token &&
                <div className='StudentReview_section2'>
                    <h5>Add Your Review</h5>
                    <div className="rate_this_container">
                        <Rating
                            name="size-large"
                            value={rating}
                            size="large"
                            onChange={(event, newValue) => setRating(newValue)}
                        />
                    </div>
                    <div className="comment_container">
                        <textarea
                            className="form-control"
                            placeholder="Write Here..."
                            id="floatingTextarea2"
                            value={review}
                            onChange={(e) => setReview(e.target.value)}
                        ></textarea>
                    </div>
                    <Button className="buttonTheme" onClick={handleRatingReviewSubmit}>
                        Post Review
                    </Button>
                </div>
            }

        </div>
    );
};
export default StudentReview;
