import React from 'react'
import "./FooterSection.scss"
import { Container } from 'react-bootstrap'
import logo from "../../images/Logo.png"
import facebook from "../../images/media/Facebook.png";
import { Link } from 'react-router-dom';
const FooterSection = () => {
  const currentYear = new Date().getFullYear();
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
  return (
   <div className='FooterSection'>
    <Container>
        <div className='d-flex justify-content-between align-items-center'>
            <img src={logo} width='200px' alt=''/>
            <div className="social-icons">
          <img src={facebook} alt="facebook" className="icon"  onClick={() => openInNewTab("https://www.facebook.com/TheLearningExpressAR")}/>
          {/* <img src={twitter} alt="facebook" className="icon"/>
          <img src={linkedIn} alt="facebook" className="icon"/> */}
        </div>
        </div>
          <hr/>
        <div className='d-flex justify-content-between align-items-center'>
        <p className='copyrights'>Copyright @ {currentYear} The Learning Express AR</p>
        <div className='contact'>
            <p><Link to="/contact-us" className='link'>Contact Us</Link></p>
            <p><Link to="/privacy-policy" className='link'>
            Privacy-Policy
          </Link></p>
        </div>
        </div>
    </Container>
   </div>
  )
}

export default FooterSection