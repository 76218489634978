import { useEffect } from "react";
import Swal from "sweetalert2";
export default function DescriptionAlerts({ text, icon, time }) {
  useEffect(() => {
    // const handleClose = () => {
    //   Swal.close();
    // };

    Swal.fire({
      position: "top-center",
      icon: icon,
      title: text,
      showConfirmButton: false,
      timer: time,
    });
  }, [text, icon, time]);

  return null;
}
